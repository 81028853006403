// JavaScript Document

$(document).ready(function () {
  submenu1 = () => {
    if (document.getElementById("submenu1").className == "submenu") {
      $("#submenu1").addClass("show");
    } else {
      $("#submenu1").removeClass("show");
    }
  };

  submenu2 = () => {
    if (document.getElementById("submenu2").className == "submenu") {
      $("#submenu2").addClass("show");
    } else {
      $("#submenu2").removeClass("show");
    }
  };

  sidebarLangSubmenu = () => {
    if (
      document.getElementById("sidebarLangSubmenu").style.display == "none" ||
      document.getElementById("sidebarLangSubmenu").style.display == ""
    ) {
      document.getElementById("sidebarLangSubmenu").style.display = "block";
    } else {
      document.getElementById("sidebarLangSubmenu").style.display = "none";
    }
  };

  headerLangSubmenu = () => {
    if (document.getElementById("headerLangSubmenu").style.display == "none") {
      document.getElementById("headerLangSubmenu").style.display = "block";
    } else {
      document.getElementById("headerLangSubmenu").style.display = "none";
    }
  };

  sidebarOpen = () => {
    $(".overlay").addClass("active");
    $("#sidebar").addClass("active");
  };

  sidebarClose = () => {
    $(".overlay").removeClass("active");
    $("#sidebar").removeClass("active");
  };

  var ekran = 1;
  var files = [];
  sendForms = (el) => {
    console.log(el);

    $form = $(el).closest(".zForms");

    $form.find(".f1, .f2, .f3, .f4").removeClass("redForm");
    $form.find(".a1, .a2, .a3, .a4").removeClass("show");
    let errors = 0;
    if ($form.find(".f1")[0].value == "") {
      $form.find(".f1").addClass("redForm");
      $form.find(".a1").addClass("show");
      errors++;
    }
    if ($form.find(".f2")[0].value == "") {
      $form.find(".f2").addClass("redForm");
      $form.find(".a2").addClass("show");
      errors++;
    }
    if ($form.find(".f3")[0].value == "") {
      $form.find(".f3").addClass("redForm");
      $form.find(".a3").addClass("show");
      errors++;
    }

    if (errors == 0) {
      // send post request
      // data = {
      // 	name : document.getElementById('f1').value,
      // 	email : document.getElementById('f2').value,
      // 	from : document.getElementById('f3').value,
      // 	to: document.getElementById('f4').value,
      // 	message : document.getElementById('f5').value,
      // 	files: files
      // }
      var formData = new FormData();

      let files = $form.find("[type=file]");
      for (var i = 0; i < files.length; i++) {
        if (files[i].files.length > 0) {
          formData.append("files[]", files[i].files[0]);
        }
      }
      formData.append("name", $form.find(".f1")[0].value);
      formData.append("email", $form.find(".f2")[0].value);
      formData.append("phone", $form.find(".f3")[0].value);
      formData.append("message", $form.find(".f5")[0].value);

      $.ajax({
        // contentType: 'application/json',
        data: formData,
        // dataType: 'json',
        success: function (data) {
          $form.find("#aTitle").removeClass("show");
          $form.find("#aTitleOK").addClass("show");
          $form.hide();
        },
        error: function (data) {
          console.log(data.responseJSON);
          for (var key in data.responseJSON) {
            let err = data.responseJSON[key];
            $("." + key).addClass("redForm");
            key = key.replace("f", "a");
            $("." + key).html(err);
            $("." + key).addClass("show");
          }
          console.log("error");
        },
        processData: false,
        contentType: false,
        type: "POST",
        url: "/sendinquiry",
      });
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.readAsBinaryString(file);
      reader.readAsText(file, "UTF-8");
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  let $them = $(".fileInput");

  $them.each(function () {
    let $that = $(this);
    let $input = $that.find('input[type="file"]');
    $input.on("click", function (e) {
      e.stopPropagation();
    });
    $input.on("change", function (e) {
      var name = e.target.files[0].name;
      $that.find(".filename").html(name);
    });
    $that.on("click", function (e) {
      $input.trigger("click");
    });
  });

  var ekran2 = 1;
  sendForms2 = () => {
    if (ekran2 == 1) {
      $("#aaTitle").addClass("show");
      $("#ff1").addClass("redForm");
      $("#ff2").addClass("redForm");
      $("#ff3").addClass("redForm");
      $("#ff4").addClass("redForm");
      $("#aa1").addClass("show");
      $("#aa2").addClass("show");
      $("#aa3").addClass("show");
      $("#aa4").addClass("show");
      ekran2 = 2;
    } else if (ekran2 == 2) {
      $("#aaTitle").removeClass("show");
      $("#aaTitleOK").addClass("show");
      document.getElementById("zzForms").style.display = "none";
      //window.location.href = "#zapitvane2";
    }
  };

  addFile = (el) => {
    $form = $(el).closest(".zForms");

    let $tmpl = $($form.find(".file_tmpl")[0].innerHTML);
    $tmpl.on("click", function () {
      let $that = $(this);
      let $input = $that.find('input[type="file"]');
      $input.on("click", function (e) {
        e.stopPropagation();
      });
      $input.on("change", function (e) {
        var name = e.target.files[0].name;
        $that.find(".filename").html(name);
      });
      $input.click();
    });
    $form.find(".filelist").append($tmpl);
    if ($form.find(".filelist").children().length >= 5) {
      $(".addMore").hide();
    }
  };

  $(".clever-link").click(function () {
    window.open($(this).data("link"), "_self");
    return false;
  });
});
